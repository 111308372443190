





























































































































import { Component, Vue } from 'vue-property-decorator';
import * as api from '@/store/api';
import AuthModule from '@/store/modules/AuthModule';

@Component
export default class DiagnosticsPage extends Vue {
  windowHeight = 0;
  windowWidth = 0;
  mounted() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    });
  }

  async testApiUnauthorisedException() {
    await api.testUnauthorisedException();
  }

  async testApiUnhandledException() {
    await api.testUnhandledException();
  }

  async testUnhandledException() {
    throw new Error('Testing unhandled error on diagnostics page');
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get username() {
    return AuthModule.profile?.UserName;
  }
  get email() {
    return AuthModule.profile?.Email;
  }
}
